require("slick-carousel")

import "slick-carousel/slick/slick.scss"
import "slick-carousel/slick/slick-theme.scss"

$('.data-slick').slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    dots: true,
    arrows: true,
    responsive: [
    {
        breakpoint: 1366,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplaySpeed: 3000,
            arrows: false,
        }
    },
    {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 3000,
            arrows: false,
        }
    }]
});
